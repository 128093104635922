import { createAuthHandler } from '@synqly/middleware/auth/api'

export { handler as default, authConfig }

const authConfig = {
  slugParam: 'management-auth',
  canReset: process.env.APP_ENV !== 'embedded',
  canSignIn: true,
  canSignUp: process.env.APP_ENV !== 'embedded',
	redirectUrl: '/prod'
}

const handler = createAuthHandler(authConfig)
