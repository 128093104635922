import { callAPI, HTTPMethods } from '@synqly/sdk/server'
import { withCSRFCheck } from './auth-utils'

export { reset }

const reset = withCSRFCheck(async (req, res) => {
  const { organization, email } = req.body

  if (!organization || !email) {
    return res.status(400).send()
  }

  const forgot = await callAPI(`/v1/users/${organization}/forgot`, {
    method: HTTPMethods.POST,
    token: process.env.MANAGEMENT_TOKEN,
    body: {
      email,
    },
  })

  if (forgot.error) {
    return res.status(forgot.error.code).send(forgot.error)
  }

  return res.status(200).send(forgot.data)
})
