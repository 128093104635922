import NextAuth from 'next-auth'
import { configuration as authConfig } from './nextauth-config'
import { signup } from './signup'
import { reset } from './reset'
import { verify } from './verify'

export { createAuthHandler }

/**
 * @param {{
 *   slugParam: string
 *   canSignIn: boolean
 *   canSignUp: boolean
 *   canVerify: boolean
 *   canReset: boolean
 *   resolveOrganizationId?: (id: string) => string | Promise<string>
 * }} config
 */
function createAuthHandler({
  slugParam = 'synqly-auth',
  canReset = true,
  canVerify = true,
  canSignUp = false,
  canSignIn = true,
  resolveOrganizationId = (id) => id,
}) {
  const Actions = {
    reset: canReset && reset,
    verify: (canReset || canVerify) && verify,
    signup: canSignUp && signup,
    default: canSignIn && nextauth,
  }

  /** @type {import('next').NextApiHandler} */
  return async (req, res) => {
    if (req.body.organization) {
      req.body.organization = await resolveOrganizationId(req.body.organization)
    }

    const { [slugParam]: params } = req.query
    const [route] = params

    const authHandler = Actions[route] ?? Actions.default
    req.query.nextauth = req.query[slugParam]
    return authHandler(req, res)
  }
}

/** @type {import('next').NextApiHandler} */
function nextauth(req, res) {
  const configuration = authConfig(req)
  return NextAuth.default(req, res, configuration)
}
