import { mergeProps } from '../component-utils'
import styles from './loader.module.css'

export { Circular }

function Circular(props) {
  return (
    <div {...mergeProps(props, { className: styles.circular })}>
      <div />
      <div />
    </div>
  )
}
