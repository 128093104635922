import { valueOf } from '@synqly/fun'

export { fetcher, resolveToken, HTTPMethods }

const HTTPMethods = {
  GET: 'GET', // The GET method requests a representation of the specified resource. Requests using GET should only retrieve data.
  HEAD: 'HEAD', // The HEAD method asks for a response identical to a GET request, but without the response body.
  CONNECT: 'CONNECT', // The CONNECT method establishes a tunnel to the server identified by the target resource.
  OPTIONS: 'OPTIONS', // The OPTIONS method describes the communication options for the target resource.
  TRACE: 'TRACE', // The TRACE method performs a message loop-back test along the path to the target resource.
  POST: 'POST', // The POST method submits an entity to the specified resource, often causing a change in state or side effects on the server.
  PUT: 'PUT', // The PUT method replaces all current representations of the target resource with the request payload.
  DELETE: 'DELETE', // The DELETE method deletes the specified resource.
  PATCH: 'PATCH', // The PATCH method applies partial modifications to a resource.
}

/**
 * @param {Token | ((...args) => Token) | { valueOf: (...args) => Token }} token
 *
 * @returns {string}
 */
function resolveToken(token) {
  /** @type {Token} */
  const accessToken = valueOf(token)

  if (typeof accessToken === 'string') {
    return accessToken
  } else if (accessToken) {
    return accessToken.secret
  }
}

// /**
//  * @param {APIPath | APIPathResolver} api
//  */
// function resolveEndpoint(api, baseURL = 'https://api.synqly.com/') {
//   const path = valueOf(api)
//   const url = `${baseURL}/${path.replace(/^\//, '')}`
// }

async function fetcher(url, accessToken, options) {
  const { method, headers, body, ...init } = options
  const authorization = accessToken
    ? { authorization: `Bearer ${accessToken}` }
    : {}

  const response = await fetch(url, {
    ...init,
    method,
    headers: {
      'content-type': 'application/json',
      ...headers,
      ...authorization,
    },
    body: serializeBody(body),
  })

  const parsed = await tryJSON(response)
  return {
    response,
    ...(response.ok
      ? { data: parsed, result: parsed?.result }
      : { error: parsed }),
  }
}

/** @param {Response} response */
async function tryJSON(response) {
  try {
    const body = await response.text()
    try {
      return body.length > 0 ? JSON.parse(body) : {}
    } catch (error) {
      error.cause = body
      return new Error('Unable to parse response', { cause: error })
    }
  } catch (error) {
    return error
  }
}

function serializeBody(body) {
  if (typeof body === 'string' || body instanceof FormData) {
    return body
  } else if (body !== null) {
    return JSON.stringify(body)
  }

  return body
}

/**
 * @template T
 * @typedef {{
 *   result: T
 * }} APIResult
 */

/**
 * @typedef {{
 *   token: Token
 *   baseURL?: URL | string
 *   requestInit?: RequestInit
 * }} APIConfiguration
 */

/**
 * @typedef {`/v1/${string}`} APIPath
 *
 * @typedef {() => APIPath} APIPathResolver
 */

/**
 * @typedef {string
 *   | {
 *       secret: string
 *       expires: Date
 *       permissions: Permission
 *     }} Token
 */

/**
 * @typedef {{
 *   roles: Role[]
 *   resourceId: string
 *   resourceType: string
 *   parentId: string
 *   id: string
 *   organizationId: string
 *   memberId: string
 * }} Permission
 */

/**
 * @typedef {{
 *   actions: string[]
 *   objects: string[]
 *   blockedApis?: string[]
 * }} Role
 */

/**
 * @template T
 * @typedef {{
 *   [prop in keyof T]: {
 *     [filter in
 *       | 'eq'
 *       | 'ne'
 *       | 'gt'
 *       | 'lt'
 *       | 'gte'
 *       | 'lte'
 *       | 'in'
 *       | 'like'
 *       | 'not in'
 *       | 'not like']: any
 *   }
 * }} Filter
 */

/**
 * @template T
 * @typedef {{
 *       [prop in keyof T]: 'desc' | 'asc'
 *     }
 *   | (keyof T)[]} Order
 */

/**
 * @typedef {{
 *   limit?: number
 *   after?: string
 *   before?: string
 * }} Page
 */
