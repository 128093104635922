import { getCsrfToken } from 'next-auth/react'

export { withCSRFCheck }

function withCSRFCheck(handler) {
  return async (req, res) => {
    const nonce = await getCsrfToken({ req: { headers: req.headers } })
    const { csrfToken } = req.body

    if (req.method !== 'POST') {
      return res.status(405).send({
        message: `unsupported method: ${req.method}`,
      })
    } else if (req.headers['content-type'] !== 'application/json') {
      return res.status(406).send({
        message: `unsupported content type: ${req.headers['content-type']}`,
      })
    } else if (csrfToken !== nonce) {
      return res.status(400).send({
        message: 'invalid CSRF token',
      })
    }

    return handler(req, res)
  }
}
