import CredentialsProvider from 'next-auth/providers/credentials'
import { HTTPMethods, callAPI } from '@synqly/sdk/server'
import { id } from '@synqly/fun'

/**
 * @typedef {import('next-auth/jwt/types').JWT} JWT
 *
 * @typedef {import('next/types').NextApiRequest} NextApiRequest
 *
 * @typedef {import('next-auth/core/types').AuthOptions} AuthOptions
 */

export { AuthError, validateToken, configuration }

/**
 * Creates a NextAuth configuration based on the incoming request.
 *
 * @param {NextApiRequest} _req
 * @returns {AuthOptions}
 */
function configuration(_req) {
  // TODO: Make use of incoming request to make configuration dynamic
  return {
    pages,
    events,
    callbacks,
    providers,
    logger,
    session,
    secret: process.env.NEXTAUTH_SECRET,
  }
}

/**
 * Represents an error that happened during authentication or validation of an
 * authenticated session.
 */
class AuthError extends Error {
  /**
   * @param {string} message Describes the error that occured
   * @param {object} options
   * @param {any} options.cause Indicates the original cause of the error
   */
  constructor(message, options) {
    super(message)
    this.cause = options?.cause
  }

  toJSON() {
    return { message: this.message, cause: this.cause }
  }

  static RefreshAccessFailed(options) {
    return new AuthError('unable to refresh session', options)
  }

  static InvalidSession(options) {
    return new AuthError('invalid session', options)
  }

  static SessionTimeout(options) {
    return new AuthError('session time out', options)
  }
}

const session = {
  // We use the `"jwt"` strategy, which means an encrypted JWT (JWE) is
  // stored in the session cookie. The JWE includes all relevant detail of
  // the session, but is not accessible to the client without knowing the
  // value of NEXTAUTH_SECRET.
  strategy: 'jwt',

  // The maximum age of a session cookie, in seconds. Can be specified with
  // the SESSION_MAX_AGE environment variable, but we default to 4
  // hours. This should only affect idle sessions.
  maxAge: process.env.SESSION_MAX_AGE ?? 4 * 60 * 60,
}

const pages = {
  signIn: '/auth/signin',
  signOut: '/auth/signout',
  error: '/auth/signin',
}

const logger = {
  error(code, metadata) {
    if (code === 'JWT_SESSION_ERROR') {
      // eslint-disable-next-line no-console -- intentional log
      console.debug(code, metadata)
    } else {
      // eslint-disable-next-line no-console -- intentional log
      console.error(code, metadata)
    }
  },
  warn(code) {
    // eslint-disable-next-line no-console -- intentional log
    console.warn(code)
  },
  debug(code, metadata) {
    // eslint-disable-next-line no-console -- intentional log
    console.debug(code, metadata)
  },
}

const events = {
  /**
   * Called when the user signs out.
   *
   * @param {object} args
   * @param {SynqlyJWT} args.token
   */
  async signOut({ token }) {
    callAPI(`/v1/auth/logoff`, {
      method: HTTPMethods.POST,
      token: token.access.secret,
    }) // Handling the response async to not hold up the UI
      .then(({ error }) => error && logError(error))
      .catch(logError)

    function logError(error) {
      // eslint-disable-next-line no-console -- intentional log
      console.error('server logoff failed', { cause: error })
    }
  },
}

const callbacks = {
  /**
   * This callback is called whenever a JSON Web Token (JWT) is created (i.e. at
   * sign in) or updated (i.e whenever a session is accessed in the client). The
   * returned value will be encrypted, and it is stored in a cookie.
   *
   * @param {object} args
   * @param {SynqlyJWT} args.token The JWT currently stored in the session
   *   cookie
   * @param {object} [args.user] The initial set of data returned by credentials
   *   provider, this will only be passed when a new session is created, after
   *   the user signs in
   * @returns {Promise<SynqlyJWT>} A new JWT to be stored in the session cookie
   * @throws {AuthError} If the JWT is invalid and/or couldn't be refreshed
   * @see https://next-auth.js.org/configuration/callbacks#jwt-callback
   *
   *
   * @typedef Token
   * @property {string} secret
   * @property {string} expires
   * @property {object} permissions
   *
   * @typedef {object} SynqlyClaims
   * @property {Token} access
   * @property {Token} refresh
   * @property {string} refreshId
   * @property {object} member
   * @property {object} organization
   *
   * @typedef {JWT & SynqlyClaims} SynqlyJWT
   */
  async jwt({ token: jwt, user: initial }) {
    if (initial) {
      jwt.member = initial.member
      jwt.organization = initial.organization
      jwt.access = initial.token.access
      jwt.refresh = initial.token.refresh
      jwt.refreshId = initial.refresh_token_id
    }

    return validateToken(jwt, { refreshToken })
  },

  /**
   * The session callback is called whenever a session is checked. Returns a
   * subset of the data stored in the JWT.
   *
   * @param {object} args
   * @param {object} args.session
   * @param {SynqlyJWT} args.token
   * @see https://next-auth.js.org/configuration/callbacks#session-callback
   */
  async session({ session, token }) {
    if (!token?.access) {
      throw AuthError.InvalidSession()
    }

    const user = await callAPI(`/v1/members/${token.member.id}`, {
      token: token.access,
    })

    if (user.error) {
      throw AuthError.InvalidSession({ cause: user.error })
    }

    const sessionData = {
      ...session,
      user: user.data.result,
      expires: token.access.expires,
      accessToken: token.access,
      organization: token.organization,
    }

    return sessionData
  },
}

const providers = [
  CredentialsProvider.default({
    name: 'Credentials',
    credentials: {
      organization: { label: 'Organization', type: 'string' },
      username: { label: 'E-mail', type: 'email' },
      password: { label: 'Password', type: 'password' },
    },
    async authorize({ organization: organizationId, username, password }) {
      const logon = await callAPI(`/v1/auth/logon/${organizationId}`, {
        method: HTTPMethods.POST,
        token: process.env.MANAGEMENT_TOKEN,
        body: {
          name: username,
          secret: password,
        },
      })

      if (logon.error) {
        throw logon.error
      }

      const { auth_code: status, auth_msg: message } = logon.data?.result ?? {}

      if (status !== 'success') {
        throw new Error(message ?? 'unknown error')
      }

      return logon.data.result
    },
  }),
]

/**
 * Validates a jwt and its access token, optionally refreshing it if it's
 * expired.
 *
 * @param {SynqlyJWT} jwt The JSON Web Token (JWT) to validate
 * @param {Object} options
 * @param {(jwt: SynqlyJWT) => SynqlyJWT | Promise<SynqlyJWT>} [options.refreshToken]
 *   Called when the access token has expired, should return a new JWT or throw an
 *   error
 * @param {(jwt: SynqlyJWT) => SynqlyJWT | null} [options.onExpired] Called when
 *   the access token has expired if `refreshToken` has not been specified,
 *   should return a new JWT or null
 * @returns {Promise<SynqlyJWT> | null} The same jwt in case it's valid and not
 *   transformed; otherwise null
 * @throws AuthError in case the given `jwt` is invalid or can't be refreshed
 */
async function validateToken(jwt, { refreshToken, onExpired = id } = {}) {
  if (!jwt?.access) {
    throw AuthError.InvalidSession({ cause: 'no access token' })
  }

  if (hasExpired(jwt.access.expires) && refreshToken) {
    // Only try refreshing the JWT if `refreshToken` is defined
    return refreshToken(jwt)
  } else if (onExpired) {
    // The JWT is still technically valid even if the access token has
    // expired, since the refresh token can be used elsewhere. The default
    // is to return the JWT as-is using the identity function.
    return onExpired(jwt)
  }

  // JWT is fine and not expired, so just return it as-is
  return jwt
}

/**
 * Default method of refreshing the token pair in the JWT.
 *
 * @param {SynqlyJWT} jwt The JSON Web Token (JWT) containing the data to
 *   refresh
 * @returns {Promise<SynqlyJWT>} A new JWT with refreshed tokens
 * @throws AuthError if unable to refresh the tokens.
 */
async function refreshToken(jwt) {
  if (!jwt?.refresh) {
    throw AuthError.InvalidSession({ cause: 'no refresh token' })
  } else if (hasExpired(jwt.refresh.expires)) {
    throw AuthError.SessionTimeout({ cause: 'expired refresh token' })
  }

  const refresh = await callAPI(`/v1/tokens/${jwt.refreshId}/refresh`, {
    token: jwt.refresh.secret,
    method: HTTPMethods.PUT,
  })

  if (refresh.error) {
    throw AuthError.RefreshAccessFailed({ cause: refresh.error })
  }

  const { result } = refresh.data
  const newToken = {
    ...jwt,
    access: result.primary.access,
    refresh: result.primary.refresh,
    refreshId: result.id,
  }

  return newToken
}

/**
 * Checks if the given value represents a time that has expired, relative to a
 * specified limit and current point in time.
 *
 * @param {string} value
 * @param {object} options
 * @param {number} [options.now] A timestamp representing the current time
 * @param {number} [options.limit] Then number of seconds to use when checking
 *   the given value against the current time, if the value is below this limit
 *   it is considered expired
 */
function hasExpired(value, { limit = 30, now = Date.now() } = {}) {
  const time = Date.parse(value)
  return Number.isNaN(time) || time - now < limit * 1000
}
