import { HTTPMethods, callAPI } from '@synqly/sdk/server'
import { withCSRFCheck } from './auth-utils'

export { signup, createOrg }

const validation = {
  eula: (value) => {
    if (value !== 'agree') {
      throw new Error('EULA must be agreed to')
    }
  },
  password: (value) => {
    if (!value || value.length < 8 || value.length > 72) {
      throw new Error('invalid password, must be 8 to 72 characters long')
    }
  },
}

const signup = withCSRFCheck(async (req, res) => {
  for (const [field, validate] of Object.entries(validation)) {
    try {
      validate(req.body[field])
    } catch (error) {
      return res.status(400).send({
        status: 400,
        message: error.message,
      })
    }
  }

  const {
    organization,
    email,
    password,
    csrfToken: _, // Assigning so it doesn't pollute profile
    ...profile
  } = req.body

  try {
    const data = await createOrg({
      organization,
      email,
      password,
      ...profile,
    })

    return res.status(201).send(data)
  } catch (error) {
    return res.status(error.cause.response.status).send(error.cause.error)
  }
})

async function createOrg({ organization, email, password, ...profile }) {
  const createOrg = await callAPI('/v1/organizations', {
    method: HTTPMethods.POST,
    token: process.env.MANAGEMENT_TOKEN,
    body: {
      fullname: organization,
      contact: email,
      member: {
        ...profile,
        name: email,
        secret: password,
        role_binding: ['administrator'],
      },
    },
  })

  if (createOrg.error) {
    throw new Error('Unable to create org', { cause: createOrg })
  }

  return {
    result: {
      owner: createOrg.data.result.member,
      token: createOrg.data.result.token,
      organization: createOrg.data.result.organization,
    },
  }
}
