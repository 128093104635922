import * as FormPrimitive from '@radix-ui/react-form'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { Text, Callout, Slot, Box } from '@radix-ui/themes'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import clsx from 'clsx'
import { mergeProps, withProps } from '../component-utils.jsx'
import { createContext } from '../context/context-utils.jsx'
import { SemanticColors } from '../styles/colors.js'
import styles from './form.module.css'
import * as Disclosure from './disclosure.jsx'
import { Button } from './button.jsx'

export {
  Root,
  Submit,
  FieldSet,
  FieldSetLegend,
  Field,
  Label,
  Control,
  Description,
  ValidityState,
  ValidationMessage,
  MessageSeverity,
}

const [FormStateContext, useFormState] = createContext('FormState', {
  defaultValue: {
    isSubmitting: false,
  },
})

const [FormFieldContext, useFormField] = createContext('FormField', {
  defaultValue: {
    hidden: undefined,
  },
})

const MessageSeverity = /** @type {const} */ ({
  INFO: 'INFO',
  ERROR: 'ERROR',
  DANGER: 'DANGER',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  IGNORED: 'IGNORED',
})

const Root = forwardRef(
  /** @typedef {(formData: FormData) => void | Promise<void>} FormAction */

  /**
   * @param {{
   *   action?: string | FormAction
   *   onActionError?: (error: Error) =>
   *     | {
   *         message: string
   *         severity?: (typeof MessageSeverity)[keyof MessageSeverity]
   *       }
   *     | { severity: typeof MessageSeverity.IGNORED }
   *     | void
   *   initialMessage?:
   *     | string
   *     | {
   *         message: String
   *         severity?: (typeof MessageSeverity)[keyof MessageSeverity]
   *       }
   *   defaultSeverity?: (typeof MessageSeverity)[keyof MessageSeverity]
   * } & Omit<
   *   import('react').ComponentPropsWithoutRef<typeof FormPrimitive.Root>,
   *   'action'
   * >} props
   */
  function Form(
    {
      action,
      onSubmit,
      onActionError,
      children,
      initialMessage,
      defaultSeverity = MessageSeverity.ERROR,
      ...props
    },
    ref,
  ) {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [formMessage, setFormMessage] = useState(
      initialMessage && {
        severity: initialMessage.severity ?? defaultSeverity,
        message: initialMessage.message ?? initialMessage,
      },
    )

    const handleSubmit = useCallback(
      /**
       * @param {import('react').SyntheticEvent<
       *   HTMLFormElement,
       *   SubmitEvent
       * >} event
       */
      async (event) => {
        const {
          currentTarget,
          nativeEvent: { submitter },
        } = event

        setIsSubmitting(true)
        onSubmit?.(event)

        if (typeof action === 'function') {
          event.preventDefault()
          const formData = new FormData(currentTarget, submitter)

          try {
            await action?.(formData)
            setFormMessage(null)
          } catch (error) {
            const custom = onActionError?.(error)

            if (custom?.severity !== MessageSeverity.IGNORED) {
              setFormMessage({
                message: custom?.message ?? error.message ?? error,
                severity: custom?.severity ?? error.severity ?? defaultSeverity,
              })
            }
          }
        }

        setIsSubmitting(false)
      },
      [onSubmit, action, onActionError, defaultSeverity],
    )

    return (
      <FormStateContext.Provider value={{ isSubmitting }}>
        <FormPrimitive.Root
          onSubmit={handleSubmit}
          ref={ref}
          {...mergeProps(props, {
            className: styles.root,
          })}
        >
          <StickyBox ref={ref}>
            <Disclosure.Root
              className={styles.formMessageDisclosureRoot}
              gap="2"
              open={!isSubmitting && !!formMessage}
            >
              <Disclosure.Content
                className={styles.formMessageDisclosureContent}
                forceMount
              >
                {!!formMessage && (
                  <FormMessage
                    message={formMessage?.message}
                    severity={formMessage?.severity}
                  />
                )}
              </Disclosure.Content>
            </Disclosure.Root>
          </StickyBox>
          {children}
        </FormPrimitive.Root>
      </FormStateContext.Provider>
    )
  },
)

const StickyBox = forwardRef(
  /** @param {import('react').PropsWithChildren} props */
  function StickyBox({ children }, ref) {
    const [isSticky, setIsSticky] = useState(false)
    const boxRef = useRef()

    useEffect(() => {
      const observer =
        ref?.current &&
        new IntersectionObserver(
          ([{ intersectionRatio }]) => {
            setIsSticky(intersectionRatio === 1)
          },
          {
            root: ref?.current,
            rootMargin: '-1px 0px 0px 0px',
            threshold: [1],
          },
        )

      observer?.observe(boxRef?.current)

      return () => observer?.disconnect()
    }, [boxRef, ref, setIsSticky])

    return (
      <Box
        className={clsx(styles.stickyBox, isSticky && styles.stickyBoxFloating)}
        ref={boxRef}
      >
        {children}
      </Box>
    )
  },
)

/**
 * @param {{
 *   message: string
 *   severity: keyof typeof MessageSeverity
 * }} props
 */
function FormMessage({
  message = 'Unexpected error',
  severity = MessageSeverity.ERROR,
}) {
  return (
    <Callout.Root
      className={styles.formMessage}
      color={SemanticColors[severity] ?? SemanticColors[MessageSeverity.ERROR]}
    >
      <Callout.Icon className={styles.formMessageIcon}>
        <InfoCircledIcon />
      </Callout.Icon>
      <Callout.Text className={styles.formMessageText}>{message}</Callout.Text>
    </Callout.Root>
  )
}

const FieldSet = forwardRef(
  /**
   * @param {{
   *   children: import('react').ReactNode
   * } & import('react').ComponentPropsWithoutRef<'fieldset'>} props
   */
  function FieldSet({ children, ...props }, ref) {
    return (
      <fieldset
        ref={ref}
        {...mergeProps(props, {
          className: styles.fieldset,
        })}
      >
        {children}
      </fieldset>
    )
  },
)

const FieldSetLegend = forwardRef(
  /**
   * @param {{
   *   children: import('react').ReactNode
   * } & import('react').ComponentPropsWithoutRef<'legend'>} props
   */
  function FieldSetLegend({ children, ...props }, ref) {
    return (
      <legend
        ref={ref}
        {...mergeProps(props, {
          className: styles.fieldsetLegend,
        })}
      >
        {children}
      </legend>
    )
  },
)

const Field = forwardRef(
  /**
   * @param {{
   *   variant?: 'normal' | 'inline' | 'inline-start' | 'inline-end' | 'hidden'
   * } & import('react').ComponentPropsWithoutRef<typeof FormPrimitive.Field>} props
   */
  function Field({ name, variant, hidden, ...props }, ref) {
    const className = [styles.field, styles[`field-variant-${variant}`]]

    if (variant === 'inline') {
      className.push(styles['field-variant-inline-start'])
    } else if (variant?.startsWith('inline-')) {
      className.push(styles['field-variant-inline'])
    }

    return (
      <FormFieldContext.Provider value={{ hidden }}>
        <FormPrimitive.Field
          hidden={hidden}
          name={name}
          ref={ref}
          {...mergeProps(props, { className: styles.field })}
        />
      </FormFieldContext.Provider>
    )
  },
)

const Label = withProps(FormPrimitive.Label, {
  className: styles.label,
})

const Control = forwardRef(
  /**
   * @param {{
   *   variant?: 'normal' | 'inline' | 'inline-start' | 'inline-end' | 'hidden'
   * } & import('react').ComponentPropsWithoutRef<typeof FormPrimitive.Field>} props
   */
  function Control({ hidden, ...props }, ref) {
    const { hidden: contextHidden } = useFormField()

    return (
      <FormPrimitive.Control
        hidden={hidden ?? contextHidden}
        ref={ref}
        {...mergeProps(props, {
          className: styles.control,
          variant: 'soft',
        })}
      />
    )
  },
)

const ValidationMessage = withProps(FormPrimitive.Message, {
  className: styles.message,
})

const Description = forwardRef(
  /** @param {import('react').ComponentPropsWithoutRef<typeof Text>} props */
  function Description({ children, ...props }, ref) {
    return (
      <Text
        as={typeof children === 'string' ? 'p' : 'div'}
        ref={ref}
        {...mergeProps(props, {
          className: styles.description,
        })}
      >
        {children}
      </Text>
    )
  },
)

const Submit = forwardRef(
  /**
   * @param {{
   *   disabledOnSubmit?: boolean
   * } & import('react').ComponentPropsWithoutRef<typeof FormPrimitive.Submit>} props
   */
  function Submit(
    { disabledOnSubmit = true, disabled, asChild, children, ...props },
    ref,
  ) {
    const { isSubmitting } = useFormState()

    const SubmitButton = asChild ? Slot : Button

    return (
      <FormPrimitive.Submit
        asChild
        disabled={disabledOnSubmit ? isSubmitting : disabled}
        ref={ref}
        type="submit"
        {...mergeProps(props, {
          loading: isSubmitting,
          className: styles.submit,
        })}
      >
        <SubmitButton>{children}</SubmitButton>
      </FormPrimitive.Submit>
    )
  },
)

const ValidityState = FormPrimitive.ValidityState
