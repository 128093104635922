import useSWR from 'swr'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { getToken } from 'next-auth/jwt'
import { HTTPMethods, resolveToken } from '@synqly/sdk/core'
import { callAPI } from '@synqly/sdk/server'
import { valueOf } from '@synqly/fun'
import { useEnv } from '../context/env'

export { useService, withServerSideFallback, API, useGet, useAPI }

function useAPI(
  path,
  {
    session,
    token,
    fallbackData,
    refreshInterval,
    shouldRetryOnError,
    ...options
  } = {},
) {
  const { NEXT_PUBLIC_API_BASE: API_BASE } = useEnv()
  const accessToken = resolveToken(token ?? session?.accessToken)

  const { data, ...swrProps } = useSWR(
    () => API_BASE && path,
    async () => {
      const { data, error } = await callAPI(path, {
        token: accessToken,
        API_BASE,
        ...options,
      })

      if (error) {
        throw error
      }

      return data
    },
    { fallbackData, refreshInterval, shouldRetryOnError },
  )

  return { ...swrProps, data, result: data?.result }
}

function makePath(urlTemplate, query, { props, options, context, session }) {
  const substituted = Object.entries(props).reduce(
    (urlTemplate, [key, value]) => {
      const resolvedValue = valueOf(value, {
        key,
        props,
        options,
        context,
        session,
      })

      return urlTemplate.replaceAll(`{${key}}`, resolvedValue ?? '')
    },
    urlTemplate,
  )

  const { order = {}, page = {}, filter = {}, expand = [] } = query

  const searchParams = new URLSearchParams([
    ...Object.entries(page).map(([opt, value]) => [
      opt,
      valueOf(value, { props, options, context, session }),
    ]),
    ...Object.entries(order).map(([field, value]) => [
      `order`,
      `${field}[${valueOf(value, {
        field,
        props,
        options,
        context,
        session,
      })}]`,
    ]),
    ...expand.map((value) => ['expand', value]),
    ...Object.entries(filter)
      .map(([field, operators]) =>
        Object.entries(operators).map(([op, value]) => [
          `filter`,
          `${field}[${op}]${valueOf(value, {
            field,
            op,
            props,
            options,
            context,
            session,
          })}`,
        ]),
      )
      .flat(),
  ])

  const search = searchParams.size ? `?${searchParams}` : ''

  const normalized = `${substituted.replace(/\/+$/, '')}${search}`
  return normalized
}

const USE_METHODS = [
  HTTPMethods.GET,
  HTTPMethods.HEAD,
  HTTPMethods.CONNECT,
  HTTPMethods.OPTIONS,
  HTTPMethods.TRACE,
]

const APIResolver = Symbol('API Resolver ID')

function API(
  urlTemplate,
  {
    props: defaultProps = {},
    order: defaultOrder,
    filter: defaultFilter,
    page: defaultPage,
    expand: defaultExpand,
    ...defaultOptions
  } = {},
) {
  resolveAPI[APIResolver] = `${urlTemplate}?${JSON.stringify({
    order: defaultOrder,
    filter: defaultFilter,
    page: defaultPage,
    expand: defaultExpand,
  })}`

  resolveAPI.toString = () => resolveAPI[APIResolver]

  return resolveAPI

  function resolveAPI({
    props,
    context,
    session,
    order = defaultOrder,
    filter = defaultFilter,
    page = defaultPage,
    expand = defaultExpand,
    ...options
  }) {
    const resolvedProps = {
      ...defaultProps,
      ...props,
    }

    const resolvedOptions = {
      ...defaultOptions,
      ...options,
      session,
    }

    const resolved = {
      path: makePath(
        urlTemplate,
        { order, filter, page, expand },
        {
          props: resolvedProps,
          options: resolvedOptions,
          context,
          session,
        },
      ),
      props: resolvedProps,
      options: resolvedOptions,
    }

    return resolved
  }
}

API.context = function getValueFromContext({
  isValid = (value) => value !== '' && value !== null && value !== undefined,
} = {}) {
  return ({ key, context }) => {
    const value = context[key]

    if (!isValid(value)) {
      throw new Error(`{${key}} not found in context`)
    }

    return value
  }
}

/** @param {Record<string, any>} props */
function useGet(resolveAPI, { props = {}, fallbackData, ...options } = {}) {
  const { data: session } = useSession()
  const router = useRouter()

  const resolved = resolveAPI({
    context: router.query,
    session,
    ...options,
    fallbackData: fallbackData?.[resolveAPI] ?? fallbackData,
    method: HTTPMethods.GET,
    props,
  })

  return useAPI(resolved.path, resolved.options)
}

function useService(
  resolveAPI,
  { props: defaultProps = {}, ...defaultOptions } = {},
) {
  const { NEXT_PUBLIC_API_BASE: API_BASE } = useEnv()
  const { data: session } = useSession()
  const router = useRouter()

  return {
    ...makeMethods(USE_METHODS, `use`, useAPI),
    ...makeMethods(Object.keys(HTTPMethods), 'call', callAPI),
  }

  function makeMethods(methods, prefix, applyAPI) {
    return methods.reduce((all, method) => {
      const fn = `${prefix}${titleCase(method)}`
      return {
        ...all,
        [fn]: ({ props = {}, ...options } = {}) => {
          const fallbackData =
            options.fallbackData?.[resolveAPI] ??
            defaultOptions.fallbackData?.[resolveAPI] ??
            options.fallbackData ??
            defaultOptions.fallbackData

          const resolved = resolveAPI({
            context: router.query,
            session,
            API_BASE,
            ...defaultOptions,
            ...options,
            fallbackData,
            method,
            props: {
              ...defaultProps,
              ...props,
            },
          })

          return applyAPI(resolved.path, resolved.options)
        },
      }
    }, {})
  }
}

function withServerSideFallback(...services) {
  /** @type {import('next').GetServerSideProps} */
  return async (context) => {
    const { req } = context
    const resolveParams = {
      context: {
        ...context.params,
        ...context.query,
      },
    }

    const token = await getToken({ req })
    if (token) {
      resolveParams.session = {
        accessToken: token.access,
      }
    }

    const results = await Promise.all(
      services.map(async (resolveAPI) => {
        try {
          const { path, options } = await resolveAPI(resolveParams)
          const { data, error } = await callAPI(path, options)
          return { data, error }
        } catch (error) {
          return { error }
        }
      }),
    )

    const { data: fallbackData, error: fallbackError } = results.reduce(
      (fallback, result, i) => {
        const resolverId = services[i][APIResolver]
        return {
          data: {
            ...fallback.data,
            [resolverId]: result.data,
          },
          error: {
            ...fallback.error,
            [resolverId]: result.error,
          },
        }
      },
      { data: {}, error: {} },
    )

    const props = JSON.parse(
      JSON.stringify({
        fallbackData,
        fallbackError,
      }),
    )

    return { props }
  }
}

function titleCase(str) {
  return `${str.slice(0, 1).toUpperCase()}${str.slice(1).toLowerCase()}`
}
