import { HTTPMethods, callAPI } from '@synqly/sdk/server'
import { withCSRFCheck } from './auth-utils'

export { verify }

const verify = withCSRFCheck(async (req, res) => {
  const { id, pin, email, password } = req.body

  const resetPassword = await callAPI(`/v1/auth/logon/${id}/reset_password`, {
    method: HTTPMethods.POST,
    token: process.env.MANAGEMENT_TOKEN,
    body: {
      pin,
      name: email,
      new_secret: password,
    },
  })

  return resetPassword.error
    ? res.status(resetPassword.response.status).send(resetPassword.error)
    : res.status(200).send(resetPassword.data)
})
